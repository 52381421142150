import store from "../..";
import {
  createProjectService,
  deleteProjectService,
  listProjectsService,
  listSingleProjectService,
  updateProjectService,
} from "../../../services/projects";
import {
  CREATE_PROJECT,
  GET_PROJECTS,
  PROJECTS_ERROR,
  PROJECTS_LOADING,
  GET_PROJECT,
  PROJECT_ERROR,
  PROJECT_LOADING,
  DELETE_PROJECT,
  UPDATE_PROJECT,
} from "../types";

export const getProjectsAction =
  ({ page, limit, next }) =>
  (dispatch) => {
    if (next) {
      let { nextPage, loading, hasMore } = store?.getState()?.projects;

      if (loading) return;
      if (!hasMore) return;
      page = nextPage;
    }
    dispatch({ type: PROJECTS_LOADING });

    return listProjectsService({
      page,
      limit,
    })
      .catch((e) =>
        dispatch({
          type: PROJECTS_ERROR,
          payload: e,
        })
      )
      .then((d) => {
        if (d.error) {
          return dispatch({
            type: PROJECTS_ERROR,
            payload: d.error,
          });
        }

        dispatch({
          type: GET_PROJECTS,
          payload: d,
        });
      });
  };

export const createProjectAction =
  ({ sendData, cb = () => {} }) =>
  (dispatch) => {
    return createProjectService({ sendData }).then((res) => {
      const { error, data } = res;
      if (error) return cb(error);
      dispatch({ type: CREATE_PROJECT, payload: data });
      cb && cb(null);
    });
  };

export const getSingleProjectAction =
  ({ projectId }) =>
  (dispatch) => {
    dispatch({ type: PROJECT_LOADING });

    return listSingleProjectService({
      projectId,
    })
      .catch((e) =>
        dispatch({
          type: PROJECT_ERROR,
          payload: e,
        })
      )
      .then((d) => {
        if (d.error) {
          return dispatch({
            type: PROJECT_ERROR,
            payload: d.error,
          });
        }

        dispatch({
          type: GET_PROJECT,
          payload: d,
        });
      });
  };

export const deleteProjectAction =
  ({ id, cb = () => {} }) =>
  (dispatch) => {
    return deleteProjectService({ id }).then((res) => {
      const { error } = res;
      if (error) {
        cb(error);
        return dispatch({
          type: PROJECT_ERROR,
          payload: error,
        });
      }

      dispatch({ type: DELETE_PROJECT, payload: { id } });
      cb && cb(null);
    });
  };
export const updateProjectAction =
  ({ updatedData, cb = () => {} }) =>
  (dispatch) => {
    return updateProjectService({ updatedData }).then((res) => {
      const { error, data } = res;
      if (error) return cb(error);
      dispatch({ type: UPDATE_PROJECT, payload: data });
      cb && cb(null);
    });
  };
