import {
  Box,
  Button,
  IconButton,
  Input,
  Modal,
  Paper,
  styled,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useState } from "react";
import { MdClose } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { updateOfferInvoiceAction } from "../../store/actions/offers";
import GenerateFileIcon from "../FileIcon";

const validationSchema = yup.object({
  invoice: yup
    .mixed()
    .test("fileSize", "File size is too large", (value) => {
      if (!value) return true;
      const maxSize = 10 * 1024 * 1024; // 10MB
      return value.size <= maxSize;
    })
    .test("fileType", "Unsupported file type", (value) => {
      if (!value) return true;
      const supportedTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "image/jpeg",
        "image/png",
        "image/gif",
      ];
      return supportedTypes.includes(value.type);
    }),
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1,
  maxWidth: "561px",
  boxShadow: "0px 4px 4px 0px #00000040",
  pb: 2,
  p: 4,
  maxHeight: "75vh",
  overflow: "auto",
  borderRadius: "10px",
  border: 0,
};

export const InvoiceDialog = ({ open, closeAdd, request, offer }) => {
  const { t } = useTranslation(["booking"]);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState();
  const [selectedFile, setSelectedFile] = useState(null);

  const formik = useFormik({
    initialValues: {
      id: offer?.id,
      requestId: request?.id,
      invoice: null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (loading) return;
      setLoading(true);

      const formData = new FormData();
      formData.append("id", values.id);
      formData.append("requestId", values.requestId);
      formData.append("invoice", values.invoice);

      updateOfferInvoiceAction({
        sendData: formData,
        isFormData: true,
        cb: (err) => {
          !err && closeAdd();
          setLoading(false);
        },
      })(dispatch);
    },
  });

  const handleFileChange = (event) => {
    const file = event?.target?.files[0];
    setSelectedFile(file);
    formik.setFieldValue("invoice", file);
  };

  const FilePreviewContainer = styled(Box)(({ theme }) => ({
    borderRadius: "6px",
    backgroundColor: theme.palette.background.secondary,
    height: "163px",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    overflow: "hidden",
    padding: theme.spacing(2),
    textAlign: "center",
  }));

  const getFileName = (file) => {
    return file ? file.name : t("invoice");
  };

  return (
    <Modal
      open={open}
      onClose={closeAdd}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
    >
      <Paper sx={style} height={1} width={1}>
        <IconButton
          sx={{
            position: "absolute",
            top: 20,
            right: 20,
            color: "text.primary",
          }}
          onClick={closeAdd}
          size="small"
        >
          <MdClose size={24} />
        </IconButton>
        <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
          <Typography
            textAlign="center"
            variant="h6"
            fontWeight={600}
            sx={{ mt: 2 }}
          >
            {t("requests.name")}
          </Typography>
          <Typography
            textAlign="center"
            variant="h6"
            fontWeight={600}
            sx={{ mb: 4 }}
            color="text.secondary"
          >
            {request?.name}
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: 1,
            }}
          >
            <label
              htmlFor="contained-button-file-attachments"
              style={{ width: "100%" }}
            >
              <Input
                id="contained-button-file-attachments"
                inputProps={{
                  accept:
                    "application/pdf, .doc, .docx, .xls, .xlsx, image/jpeg, image/png, image/gif",
                  multiple: false,
                }}
                type="file"
                sx={{ display: "none" }}
                onChange={handleFileChange}
              />
              <FilePreviewContainer>
                {GenerateFileIcon(selectedFile)}
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ mt: 1, wordBreak: "break-word" }}
                >
                  {getFileName(selectedFile)}
                </Typography>
                {formik.errors.invoice && formik.touched.invoice && (
                  <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                    {formik.errors.invoice}
                  </Typography>
                )}
              </FilePreviewContainer>
            </label>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              sx={{ mt: 4, width: 1, maxWidth: "182px", py: 1 }}
              disabled={loading}
            >
              {t("send")}
            </Button>
          </Box>
        </form>
      </Paper>
    </Modal>
  );
};
