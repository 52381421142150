import { List, ListItem, ListItemText, Popover } from "@mui/material";

export default function DropDownMenu({
  anchorEl,
  handleClose,
  data,
  location,
}) {
  return (
    <Popover
      disableScrollLock={true}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: location ?? "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: location ?? "center",
      }}
    >
      <List>
        {data?.map((item, index) => {
          return (
            <ListItem
              key={index}
              onClick={item?.function}
              sx={{
                transition: "background-color 0.5s ease-in-out",
                "&:hover": {
                  bgcolor: "primary.main",
                  cursor: "pointer",
                  "& > *": {
                    color: "#FFF",
                  },
                },
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: "1em",
              }}
            >
              {item?.icon}
              <ListItemText
                primary={item?.text}
                sx={{
                  transition: "color 0.5s ease-in-out",
                  color: item?.color ?? "black",
                }}
              />
            </ListItem>
          );
        })}
      </List>
    </Popover>
  );
}
