import { Box, Button, Link, Typography } from "@mui/material";
import UserAvatar from "../../../Components/UserAvatar";
import { getDate } from "../../../shared/getDate";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCallback, useState } from "react";
import EastIcon from "@mui/icons-material/East";
import { InvoiceDialog } from "../../../Components/InvoiceDialog";
import FileRenderer from "./../../../Components/UrlFileRender/index";

const OfferItem = ({
  offer,
  openOfferModal,
  removeDate,
  isRequest,
  isProject,
  isReview = false,
  status,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(["settings", "projectDetails"]);

  const [invoiceOpen, setInvoiceOpen] = useState(false);

  const openInvoice = useCallback(() => setInvoiceOpen(true), []);
  const closeInvoice = useCallback(() => setInvoiceOpen(false), []);

  const navigate = useNavigate();

  const request = offer?.request;
  const project = request?.project;

  return (
    <Box
      sx={{
        width: 1,
        borderRadius: "6px",
        backgroundColor: "#E0E0E0",
        mb: 2,
        p: 2,
      }}
    >
      <InvoiceDialog
        open={invoiceOpen}
        closeAdd={closeInvoice}
        request={request}
        offer={offer}
      />

      <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}>
        <UserAvatar
          src={isRequest ? offer?.image : request?.image}
          alt={isRequest ? offer?.name : request?.name}
          sx={{ width: "62px", height: "62px", mr: 2 }}
        />

        <Box width={1}>
          <Box
            onClick={() => {
              isProject
                ? navigate(`/request/${offer?.id}`)
                : openOfferModal({ offer, request, project });
            }}
            sx={{ cursor: "pointer" }}
          >
            <Typography>
              {t("requests.request_name")}:{" "}
              <Typography component="span" color="text.secondary">
                {isRequest ? offer?.name : request?.name}
              </Typography>
            </Typography>
            <Box
              sx={{
                width: 1,
                // display: "flex",
                mt: 1,
                // flexDirection: { xs: "column", md: "row" },
              }}
            >
              <Typography color="text.secondary" sx={{ mr: 5 }}>
                {t("requests.offer_number")}: 0000{offer?.id}
              </Typography>
              <Typography color="text.secondary" sx={{ mr: 5 }}>
                {t("requests.project_name")} :{" "}
                {isRequest ? offer?.project?.name : project?.name}
              </Typography>
              {project?.location && (
                <Box
                  sx={{
                    zIndex: 9,
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <Typography color="text.secondary" sx={{ mr: 1 }}>
                    {t("requests.location")} :{" "}
                  </Typography>

                  <Link
                    href={project?.location}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      color: "secondary.main",
                      textDecorationColor: "#2E4CEB",
                    }}
                  >
                    <Typography variant="body2" sx={{ cursor: "pointer" }}>
                      {t("projectDetails:google_maps")}
                    </Typography>
                  </Link>
                </Box>
              )}

              {offer?.price && (
                <Typography color="text.secondary" sx={{ mr: 5 }}>
                  {t("projectDetails:price")} : {offer?.price}
                </Typography>
              )}

              {isReview && offer?.status === 2 && (
                <Typography color="error" sx={{ mr: 5 }}>
                  {t("projectDetails:note_offer_other")}
                </Typography>
              )}
            </Box>

            {status === "accepted" && (
              <Box
                sx={{
                  width: 1,
                  mt: 1,
                }}
              >
                <Typography variant="h6" color="text.secondary" sx={{ mr: 5 }}>
                  {t("requests.user_info")}:
                </Typography>

                <Typography color="text.secondary" sx={{ mr: 5 }}>
                  {t("requests.username")}: {offer?.user?.username}
                </Typography>
                <Typography color="text.secondary" sx={{ mr: 5 }}>
                  {t("requests.mobile")}: {offer?.user?.mobile}
                </Typography>
                <Typography color="text.secondary" sx={{ mr: 5 }}>
                  {t("requests.email")}: {offer?.user?.email}
                </Typography>
                <Typography color="text.secondary" sx={{ mr: 5 }}>
                  {t("requests.tax_number")}: {offer?.user?.taxNumber ?? "N/A"}
                </Typography>
                <Typography color="text.secondary" sx={{ mr: 5 }}>
                  {t("requests.company")}: {offer?.user?.info?.company}
                </Typography>
                <Typography color="text.secondary" sx={{ mr: 5 }}>
                  {t("requests.national_address")}: {offer?.user?.info?.address}
                </Typography>
              </Box>
            )}
          </Box>

          {status === "accepted" && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "0.5em",
                mt: "2em",
                alignItems: "start",
              }}
            >
              <Typography variant="h6" color="text.secondary">
                {t("requests.invoice")}:
              </Typography>
              {offer?.invoice && <FileRenderer fileUrl={offer?.invoice} />}
              <Button onClick={openInvoice}>
                {offer?.invoice ? t("update_invoice") : t("attach_invoice")}
                <EastIcon sx={{ ml: 1 }} />
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      {!removeDate && (
        <Typography color="text.secondary" textAlign="end">
          {getDate(offer?.createdAt, language)}
        </Typography>
      )}
    </Box>
  );
};

export default OfferItem;
