import { Box, Button, Grid, List, ListItem, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import HomeTitle from "../HomeTitle";
import MultiAnimation from "../Animations/MultiAnimation";
import MainButton from "./../MainButton/index";
import ImageWithPlaceholder from "../ImageWithPlaceholder";
import Loader from "../Loader";
import { emptyProjects } from "../../assets/index";
import EmptyData from "./../EmptyData/index";
import { Link, useNavigate } from "react-router-dom";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { getDate } from "../../shared/getDate";
import { useInView } from "react-intersection-observer";

export default function ImageWithOverlappedCard({
  data,
  loading,
  hasButton = true,
  hasTitle = true,
  emptyTitle,
  emptySubtitle,
  linkUrl,
  renderId,
  renderName,
  hasPrice = false,
}) {
  const { t } = useTranslation(["global", "booking"]);
  const navigate = useNavigate();

  const handleProjectClick = (id) => {
    navigate(`/${linkUrl}/${id}`);
  };

  const { ref, inView } = useInView({
    threshold: 0,
  });

  return (
    <>
      <Box
        sx={{
          p: { sm: "1em", xs: 0 },
          display: "flex",
          flexDirection: "column",
          gap: { sm: "2em", xs: 0 },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            position: "relative",
          }}
        >
          {hasTitle && (
            <HomeTitle
              subtitle={{
                en: "PROJECTS",
                ar: "المشاريع",
              }}
              title={{
                en: "Latest projects was added",
                ar: "تمت إضافة أحدث المشاريع",
              }}
            />
          )}

          {hasButton && (
            <Button variant="text" component={Link} to={"/projects"}>
              <Typography
                variant="button"
                sx={{
                  color: "primary.main",
                }}
              >
                {t("see_more")}
              </Typography>
            </Button>
          )}
        </Box>

        <>
          <Grid container spacing={2} ref={ref}>
            {data?.length > 0 ? (
              data?.map((item, index) => (
                <Grid item key={index} xs={12} sm={6} lg={3}>
                  <Box sx={{ display: { xs: "none", sm: "block" } }}>
                    <MultiAnimation
                      index={index}
                      delay={350}
                      bottom
                      inView={inView}
                    >
                      <Box
                        sx={{
                          "&:hover": {
                            cursor: "pointer",
                            boxShadow: "-10px 10px 40px rgba(0, 0, 0, 0.2)",
                          },
                          transition: "0.5s all",
                          borderRadius: "20px",
                          textDecoration: "none",
                        }}
                        onClick={() =>
                          handleProjectClick(
                            renderId ? renderId(item) : item?.id
                          )
                        }
                      >
                        <Box
                          sx={{
                            width: 1,
                            height: "300px",
                            overflow: "hidden",
                            borderRadius: "10px",
                          }}
                        >
                          <ImageWithPlaceholder
                            img={
                              item?.image
                                ? item?.image
                                : item?.request?.image
                                ? item?.request?.image
                                : undefined
                            }
                            height={"300px"}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "90%",
                            transform: "translate(0, -25%)",
                            borderRadius: "20px",
                            p: "1em",
                            bgcolor: "background.paper",
                            gap: "2em",
                            justifyContent: "space-between",
                            margin: "0 auto",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "0.5em",
                            }}
                          >
                            <Typography
                              variant="h6"
                              sx={{ color: "#000", fontWeight: 400 }}
                            >
                              <span style={{ fontWeight: 600 }}>
                                {t("project_name")}:
                              </span>{" "}
                              {renderName ? renderName(item) : item?.name}
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{ color: "#000", fontWeight: 400 }}
                            >
                              <span style={{ fontWeight: 600 }}>
                                {t("request_number")}:
                              </span>{" "}
                              PR-000{item.id}
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                WebkitLineClamp: "1",
                                WebkitBoxOrient: "vertical",
                              }}
                              color="text.secondary"
                            >
                              <FiberManualRecordIcon
                                sx={{ fontSize: "8px", mx: 2 }}
                              />
                              {t("date")}: {getDate(item?.createdAt)}
                            </Typography>
                            {hasPrice && (
                              <Typography
                                variant="subtitle2"
                                sx={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  display: "-webkit-box",
                                  WebkitLineClamp: "1",
                                  WebkitBoxOrient: "vertical",
                                }}
                                color="text.secondary"
                              >
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "8px", mx: 2 }}
                                />
                                {t("price")}: {item?.price}
                              </Typography>
                            )}

                            <List>
                              {item?.features?.map((feature, index) => (
                                <ListItem
                                  disablePadding
                                  key={index}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "1em",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      width: "5px",
                                      height: "5px",
                                      borderRadius: "50%",
                                      bgcolor: "#615F5F",
                                    }}
                                  />
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      fontWeight: "400",
                                      color: "#615F5F",
                                    }}
                                  >
                                    {feature}
                                  </Typography>
                                </ListItem>
                              ))}
                            </List>
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <MainButton
                              variant={"contained"}
                              text={t("details")}
                              color={"#FFF"}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </MultiAnimation>
                  </Box>

                  <Box
                    sx={{
                      display: { xs: "flex", sm: "none" },
                      justifyContent: "space-between",
                      alignItems: "center",
                      maxHeight: "300px",
                      height: "250px",
                      borderTop: "1px solid #C1C1C180",
                      borderBottom: "1px solid #C1C1C180",
                      mb: 2,
                    }}
                    onClick={() =>
                      handleProjectClick(renderId ? renderId(item) : item?.id)
                    }
                  >
                    <Box
                      sx={{
                        width: 0.4,
                        height: "150px",
                        minHeight: "150px",
                        overflow: "hidden",
                        borderRadius: "10px",

                        mx: 0.5,
                        mt: 2.5,
                      }}
                    >
                      <ImageWithPlaceholder
                        img={
                          item?.image
                            ? item?.image
                            : item?.request?.image
                            ? item?.request?.image
                            : undefined
                        }
                        height={"150px"}
                      />
                    </Box>
                    <Box
                      sx={{
                        width: 0.6,
                        display: "flex",
                        flexDirection: "column",
                        px: 1,
                        borderRadius: "20px",
                        bgcolor: "background.paper",
                        justifyContent: "space-between",
                        height: "150px",
                        minHeight: "200px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.5em",
                          mt: 4,
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{ color: "#000", fontWeight: 400 }}
                        >
                          <span style={{ fontWeight: 600 }}>
                            {t("project_name")}:
                          </span>{" "}
                          {item.name}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          sx={{ color: "#000", fontWeight: 400 }}
                        >
                          <span style={{ fontWeight: 600 }}>
                            {t("request_number")}:
                          </span>{" "}
                          PR-000{item.id}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "1",
                            WebkitBoxOrient: "vertical",
                          }}
                          color="text.secondary"
                        >
                          <FiberManualRecordIcon
                            sx={{ fontSize: "8px", mx: 2 }}
                          />
                          {t("date")}: {getDate(item?.createdAt)}
                        </Typography>

                        <List>
                          {item?.features?.map((feature, index) => (
                            <ListItem
                              disablePadding
                              key={index}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "1em",
                              }}
                            >
                              <Box
                                sx={{
                                  width: "5px",
                                  height: "5px",
                                  borderRadius: "50%",
                                  bgcolor: "#615F5F",
                                }}
                              />
                              <Typography
                                variant="body1"
                                sx={{
                                  fontWeight: "400",
                                  color: "#615F5F",
                                }}
                              >
                                {feature}
                              </Typography>
                            </ListItem>
                          ))}
                        </List>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <MainButton
                          variant={"contained"}
                          text={t("details")}
                          color={"#FFF"}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              ))
            ) : (
              <>
                {!loading && (
                  <EmptyData
                    img={emptyProjects}
                    title={emptyTitle ?? t("booking:projects.no_projects")}
                    subtitle={
                      emptySubtitle ?? t("booking:projects.no_projects_sub")
                    }
                  />
                )}
              </>
            )}
          </Grid>
          {loading && (
            <Box
              sx={{
                width: 1,
                display: "flex",
                justifyContent: "center",
                py: 5,
              }}
            >
              <Loader />
            </Box>
          )}
        </>
      </Box>
    </>
  );
}
