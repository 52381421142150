import { BrowserRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { appConfigAction } from "./store/actions/app/index";
import Router from "./Router";
import { useEffect } from "react";
import { Box } from "@mui/material";
import Loader from "./Components/Loader";

export const AppLoader = () => {
  return (
    <Box
      sx={{ height: "100vh" }}
      display="flex"
      alignItems="center"
      justifyContent="center"
      justifyItems="center"
    >
      <Loader />
    </Box>
  );
};

const Splash = () => {
  const dispatch = useDispatch();

  const appLoading = useSelector((state) => state.app.loading);

  useEffect(() => {
    dispatch(appConfigAction());
  }, [dispatch]);

  if (appLoading) return <AppLoader />;

  return (
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  );
};

export default Splash;
