import { Badge, Box, Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect } from "react";
import { KeyboardDoubleArrowDownOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import ImageWithOverlappedCard from "../../../../Components/ImageWithOverlappedCard";
import { getRequestsAction } from "../../../../store/actions/requests";
import OfferItem from "../../../Settings/OffersList/OfferItem";
import { requestsEmpty } from "../../../../assets";
import Loader from "../../../../Components/Loader";
import { listRequestsService } from "../../../../services/requests";

export default function NewRequests({ onUnreadCountChange }) {
  const { t } = useTranslation(["global", "booking"]);

  const dispatch = useDispatch();

  const requests = useSelector((s) => s?.requests?.data);
  const loading = useSelector((s) => s?.requests?.loading);
  const hasMore = useSelector((s) => s?.requests?.hasMore);
  const count = useSelector((s) => s?.requests?.count);

  const getRequests = useCallback(
    ({ page, next }) => {
      dispatch &&
        getRequestsAction({
          page,
          limit: 6,
          next,
          hasOffers: true,
          isNew: true,
        })(dispatch);
    },
    [dispatch]
  );

  useEffect(() => {
    getRequests && getRequests({ page: 1 });
  }, [getRequests]);

  const handleSeeMore = () => {
    getRequests({ next: true });
  };

  const getUnread = useCallback(async ({ page }) => {
    try {
      const response = await listRequestsService({
        page,
        limit: 6,
        hasOffers: true,
        isNew: true,
      });

      if (response.error) {
        console.error("Error fetching requests:", response.error);
      } else {
        onUnreadCountChange(response?.data?.unreadRequestCount);
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    }
  }, []);

  useEffect(() => {
    getUnread && getUnread({ page: 1 });
  }, [getUnread]);

  return (
    <Box id="NewRequests">
      {/* <ImageWithOverlappedCard
        data={requests?.resData}
        loading={loading}
        hasTitle={false}
        hasButton={false}
        emptyTitle={t("no_requests_title")}
        emptySubtitle={t("no_requests_subtitle")}
        linkUrl={"request"}
      /> */}

      <Box sx={{ mt: "2em" }}>
        {requests?.length > 0 ? (
          <>
            {requests?.map((request) =>
              request?.isRead ? (
                <OfferItem
                  key={request?.id}
                  offer={request}
                  isRequest
                  isProject
                />
              ) : (
                <Badge
                  color="error"
                  variant="dot"
                  sx={{
                    width: 1,
                    borderRadius: "6px",
                    backgroundColor: "#E0E0E0",
                    mb: 2,
                    cursor: "pointer",
                    "& .MuiBadge-dot": {
                      width: "15px",
                      height: "15px",
                      borderRadius: "50%",
                    },
                  }}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  showZero
                >
                  <OfferItem
                    key={request?.id}
                    offer={request}
                    isRequest
                    isProject
                  />
                </Badge>
              )
            )}
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: 1,
              py: 8,
            }}
          >
            <img src={requestsEmpty} alt="" />
            <Typography color="text.secondary" fontWeight="bold" sx={{ mt: 2 }}>
              {t("requests.empty")}
            </Typography>
            <Typography variant="subtitle2" color="text.secondary">
              {t("requests.empty_sub")}
            </Typography>
          </Box>
        )}
        {loading && (
          <Box
            sx={{ width: 1, display: "flex", justifyContent: "center", py: 5 }}
          >
            <Loader />
          </Box>
        )}
        {hasMore && !loading && requests?.length > 0 && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button onClick={() => getRequests({ next: true })}>
              <KeyboardDoubleArrowDownOutlined />
            </Button>
          </Box>
        )}
      </Box>

      {/* {requests?.length > 0 && hasMore && (
        <Box sx={{ display: "flex", justifyContent: "center", margin: "2em" }}>
          <Button
            variant="text"
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              gap: "1em",
            }}
            onClick={handleSeeMore}
          >
            <Typography variant="body1" sx={{ color: "#615F5F" }}>
              {t("see_more")}
            </Typography>
            <KeyboardArrowDown sx={{ color: "#615F5F" }} />
          </Button>
        </Box>
      )} */}
    </Box>
  );
}
