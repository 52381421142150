import { Box, IconButton, Typography } from "@mui/material";
import { WarningAmber, ArrowRightAlt, Close } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export default function DataBanner() {
  const {
    t,
    i18n: { language: lang },
  } = useTranslation(["global"]);

  const [isBannerVisible, setBannerVisibility] = useState(true);

  const userData = useSelector((state) => state?.auth?.user);

  useEffect(() => {
    if (userData?.info?.company && userData?.info?.taxNumber) {
      setBannerVisibility(false);
    } else {
      const storedData = localStorage.getItem("bannerState");

      if (storedData) {
        const { isVisible, timestamp } = JSON.parse(storedData);

        if (isVisible) {
          setBannerVisibility(true);
        } else {
          const currentTime = Date.now();
          if (currentTime - timestamp > 24 * 60 * 60 * 1000) {
            setBannerVisibility(true);
          } else {
            setBannerVisibility(false);
          }
        }
      }
    }
  }, [userData]);

  const handleCloseBanner = () => {
    setBannerVisibility(false);
    const data = { isVisible: false, timestamp: Date.now() };
    localStorage.setItem("bannerState", JSON.stringify(data));
  };

  return (
    <>
      {isBannerVisible && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "1em",
            alignItems: "center",
            bgcolor: "#D73C1E",
            position: "sticky",
            p: "0.5em",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              gap: "1em",
              alignItems: "center",
            }}
          >
            <WarningAmber sx={{ color: "#FFF" }} />
            <Typography variant="body2" sx={{ color: "#FFF" }}>
              {t("data_banner_title")}{" "}
              <Typography
                component={Link}
                to={"/settings?tab=editProfile"}
                variant="body2"
                sx={{ color: "#FFF" }}
              >
                {t("data_banner_button")}
              </Typography>
            </Typography>
            <ArrowRightAlt
              sx={{
                color: "#FFF",
                transform: lang === "ar" ? "rotate(180deg)" : "rotate(0)",
              }}
            />
          </Box>

          <IconButton
            onClick={handleCloseBanner}
            sx={{
              "&:hover": {
                "& > *": {
                  transform: "scale(1.2)",
                },
              },
            }}
          >
            <Close
              sx={{ color: "#FFF", transition: "transform 0.3s ease-in-out" }}
            />
          </IconButton>
        </Box>
      )}
    </>
  );
}
