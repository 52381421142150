import {
  CONTACTS_LOADED,
  CONTACTS_ERROR,
  CONTACTS_LOADING,
} from "../actions/types";
import { createReducer } from "@reduxjs/toolkit";
const initialState = {
  data: [],
  thisPage: 0,
  allPages: 0,
  error: false,
  loading: false,
  hasMore: true,
};

const contactsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(CONTACTS_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(CONTACTS_LOADED, (state, { payload }) => {
      state.data =
        payload?.thisPage === 1
          ? payload?.data
          : [...state?.data, ...payload?.data];
      state.hasMore = payload.hasMore;
      state.thisPage = payload.thisPage;
      state.allPages = payload.allPages;
      state.loading = false;
    })
    .addCase(CONTACTS_ERROR, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
});

export default contactsReducer;
