import { Provider } from "react-redux";
import getDesignTokens from "./theme";
import { useMemo, Suspense } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import "moment/locale/ar";
import moment from "moment";
import { useTranslation } from "react-i18next";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";
import Loader from "./Components/Loader";
import store from "./store";
import Splash from "./Splash";
import {
  SnackbarUtilsConfigurator,
  snackIcons,
  snackStyle,
  StyledSnackbarProvider,
} from "./shared/snacks";
import SnackbarCloseButton from "./shared/SnackClose";

function App() {
  const { i18n } = useTranslation();

  // const [mode, setMode] = useState(localStorage?.getItem("mode") || "light");
  const mode = "light";

  const isRTL = i18n.language === "ar" ? "rtl" : "ltr";
  moment.locale(i18n.language);
  document.body.setAttribute("dir", isRTL);

  const theme = useMemo(() => {
    return createTheme({
      ...getDesignTokens({ mode }),
      direction: isRTL,
    });
  }, [mode, isRTL]);

  let plugins = [prefixer];
  if (isRTL === "rtl") {
    plugins.push(rtlPlugin);
  }

  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: plugins,
  });

  function resetHeight() {
    // reset the body height to that of the inner browser
    document.body.style.height = window.innerHeight + "px";
    let divElement = document?.querySelector("#app");
    if (divElement) {
      divElement.style.height = window.innerHeight + "px";
    }
  }

  window.addEventListener("resize", resetHeight);

  resetHeight();

  return (
    <Suspense fallback={<Loader />}>
      <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div id="app">
            <Suspense fallback={<Loader />}>
              <StyledSnackbarProvider
                maxSnack={4}
                anchorOrigin={{ horizontal: "right", vertical: "top" }}
                classes={{
                  variantInfo: snackStyle.info,
                }}
                iconVariant={snackIcons}
                dense
                action={(snackbarKey) => (
                  <SnackbarCloseButton snackbarKey={snackbarKey} />
                )}
              >
                <SnackbarUtilsConfigurator />
                <Provider store={store}>
                  <Splash />
                </Provider>
              </StyledSnackbarProvider>
            </Suspense>
          </div>
        </ThemeProvider>
      </CacheProvider>
    </Suspense>
  );
}

export default App;
