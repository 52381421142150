import store from "../..";
import { listCategoriesService } from "../../../services/categories";
import { CATEGORIES_LOADING, CATEGORIES_ERROR, GET_CATEGORIES } from "../types";

export const getCategoriesAction =
  ({ page, limit, next }) =>
  (dispatch) => {
    if (next) {
      let { nextPage, loading, hasMore } = store?.getState()?.categories;

      if (loading) return;
      if (!hasMore) return;
      page = nextPage;
    }
    dispatch({ type: CATEGORIES_LOADING });

    return listCategoriesService({
      page,
      limit,
    })
      .catch((e) =>
        dispatch({
          type: CATEGORIES_ERROR,
          payload: e,
        })
      )
      .then((d) => {
        if (d.error) {
          return dispatch({
            type: CATEGORIES_ERROR,
            payload: d.error,
          });
        }

        dispatch({
          type: GET_CATEGORIES,
          payload: d,
        });
      });
  };
