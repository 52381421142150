import { getRequest } from "../connection/network";

const categoryApi = "category";

export const listCategoriesService = async ({ page, limit }) => {
  try {
    const token = localStorage.getItem("token");
    const {
      data: {
        data,
        meta: { thisPage, nextPage },
      },
    } = await getRequest({
      token,
      query: { page, limit },
      endPoint: categoryApi,
    });

    return {
      data,
      thisPage: parseInt(thisPage),
      nextPage: parseInt(nextPage),
      hasMore: data?.length === limit,
    };
  } catch (error) {
    const sError =
      error?.response?.data?.error?.message || "Unknown error occurred";
    return { error: sError };
  }
};
