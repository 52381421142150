import {
  Typography,
  Select,
  FormControl,
  MenuItem,
  CircularProgress,
  FormHelperText,
} from "@mui/material";
const SelectInput = ({
  name,
  label,
  placeholder,
  formik,
  options,
  renderOptionLabel,
  renderValue,
  loading,
  ...props
}) => {
  return (
    <>
      <Typography sx={{ mb: 1 }} fontWeight={500}>
        {label}
      </Typography>
      <FormControl
        sx={{ width: 1 }}
        error={formik?.touched[name] && Boolean(formik?.errors[name])}
      >
        <Select
          {...props}
          fullWidth
          displayEmpty
          sx={{
            borderRadius: "12px",
            border: "1px solid #E5E7EB",
            backgroundColor: "#fff",
          }}
          inputProps={{ name }}
          value={formik?.values[name]}
          onChange={formik?.handleChange}
          renderValue={
            formik?.values[name]
              ? undefined
              : (selected) => {
                  if (!selected) {
                    return (
                      <Typography color="text.icon">{placeholder}</Typography>
                    );
                  }
                }
          }
        >
          {loading && (
            <MenuItem
              value={"loading"}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <CircularProgress size={18} />
            </MenuItem>
          )}
          {options?.map((e, i) => (
            <MenuItem key={i} value={renderValue ? renderValue(e) : e}>
              {renderOptionLabel ? renderOptionLabel(e) : e}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>
          {formik?.touched[name] && formik?.errors[name]
            ? formik?.errors[name]
            : ""}
        </FormHelperText>
      </FormControl>
    </>
  );
};

export default SelectInput;
