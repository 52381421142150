import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Input,
  Slide,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useTranslation } from "react-i18next";
import { getCategoriesAction } from "../../store/actions/categories";
import { createRequestAction } from "../../store/actions/requests";
import TextInput from "../../Pages/AddRequest/components/TextInput";
import SelectInput from "../SelectInput";
import { getProjectsAction } from "../../store/actions/projects";
import { Close } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddRequestFormDialog = ({ open, close, mobile }) => {
  const dispatch = useDispatch();
  const {
    t,
    i18n: { language: lang },
  } = useTranslation(["booking", "auth"]);
  const [image, setImage] = useState();
  const [lineCount, setLineCount] = useState(1);
  const [loading, setLoading] = useState(false);

  const categories = useSelector((s) => s?.categories?.data);
  const hasMore = useSelector((s) => s?.categories?.hasMore);
  const loadingCategories = useSelector((s) => s?.categories?.loading);

  const getCategories = useCallback(
    ({ next = false }) => {
      getCategoriesAction({ next, limit: 12 })(dispatch);
    },
    [dispatch]
  );

  const onScrolled = (e) => {
    if (loading) return;
    const { scrollHeight, scrollTop, clientHeight } = e.target;
    if (scrollTop + clientHeight + 50 >= scrollHeight && hasMore) {
      getCategories({ next: true });
    }
  };

  useEffect(() => {
    getCategories && getCategories({});
  }, [getCategories]);

  const projects = useSelector((s) => s?.projects?.data);
  const projectsHasMore = useSelector((s) => s?.projects?.hasMore);
  const loadingProjects = useSelector((s) => s?.projects?.loading);

  const getProjects = useCallback(
    ({ next = false }) => {
      getProjectsAction({ next, limit: 12 })(dispatch);
    },
    [dispatch]
  );

  const onScrolledProjects = (e) => {
    if (loading) return;
    const { scrollHeight, scrollTop, clientHeight } = e.target;
    if (scrollTop + clientHeight + 50 >= scrollHeight && projectsHasMore) {
      getProjects({ next: true });
    }
  };

  useEffect(() => {
    getProjects && getProjects({});
  }, [getProjects]);

  const uploadImage = (e) => {
    const file = e?.target?.files[0];
    setImage({ file, media: URL.createObjectURL(file) });
  };

  const validationSchema = useMemo(
    () =>
      yup.object({
        name: yup
          .string(t("auth:request_name"))
          .min(3, t("auth:too_short"))
          .max(50, t("auth:too_long"))
          .required(t("auth:request_required")),
        projectId: yup
          .number(t("requests.project_required"))
          .required(t("requests.project_required")),
        categoryId: yup
          .number(t("requests.category_required"))
          .required(t("requests.category_required")),
      }),
    [t]
  );

  const formik = useFormik({
    initialValues: {
      name: "",
      projectId: "",
      categoryId: "",
      comment: "1. ",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (loading) return;
      setLoading(true);
      const sendData = new FormData();
      for (const [key, value] of Object.entries(values)) {
        value && sendData.append(key, value);
      }
      image && sendData.append("image", image?.file);

      createRequestAction({
        sendData,
        cb: (err) => {
          !err && setLoading(false);
        },
      })(dispatch);

      close();
    },
  });

  // Helper function to generate numbered comments
  const getNumberedComments = (comments) => {
    const lines = comments.split("\n");
    return lines
      .map((line, index) => `${index + 1}. ${line.replace(/^\d+\.\s*/, "")}`)
      .join("\n");
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      const cursorPosition = event.target.selectionStart;

      const newComment = formik.values.comment;

      // Insert a new line
      const newLines = newComment.split("\n");
      newLines.splice(cursorPosition, 0, ""); // Insert an empty line at the cursor position

      // Update the comment field with the new lines and correct numbering
      formik.setFieldValue("comment", getNumberedComments(newLines.join("\n")));
    } else if (event.keyCode === 8) {
      // Handle backspace key
      const newComment = formik.values.comment;
      const cursorPosition = event.target.selectionStart;

      // If cursor is at the start of a line, delete that line and re-number the rest
      const newLines = newComment.split("\n");
      const currentLineIndex =
        newComment.substring(0, cursorPosition).split("\n").length - 1;

      if (cursorPosition > 0 && newLines[currentLineIndex].trim() === "") {
        newLines.splice(currentLineIndex, 1); // Remove the line if it's empty
      }

      // Update the comment field with the updated lines and correct numbering
      formik.setFieldValue("comment", getNumberedComments(newLines.join("\n")));
    }
  };
  return (
    <Dialog
      open={open}
      onClose={close}
      TransitionComponent={Transition}
      keepMounted
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "25px",
          bgcolor: "#F4F5F7",
          maxHeight: mobile ? "100%" : "80%",
          maxWidth: mobile ? "100%" : "80%",
        },
      }}
    >
      <DialogTitle sx={{ display: "flex", justifyContent: "end" }}>
        <IconButton
          sx={{
            "&:hover": {
              "& > *": {
                transform: "scale(1.5)",
                color: "red",
              },
            },
          }}
          onClick={close}
        >
          <Close sx={{ transition: "transform 0.2s ease" }} />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ mb: "2em" }}>
        <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
          <Typography variant="h6" fontWeight={600} sx={{ mb: 4 }}>
            {t("panel.add_request")}
          </Typography>
          <Grid container rowSpacing={4}>
            <Grid item xs={12}>
              <TextInput
                label={t("requests.name")}
                placeholder={t("requests.name_placeholder")}
                name="name"
                formik={formik}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>

            <Grid item xs={12}>
              <SelectInput
                label={t("auth:form.project")}
                placeholder={t("auth:form.project")}
                name="projectId"
                formik={formik}
                options={projects}
                renderValue={(option) => option?.id}
                renderOptionLabel={(option) => option?.name}
                loading={loadingProjects}
                MenuProps={{
                  PaperProps: {
                    onScroll: onScrolledProjects,
                    sx: { maxHeight: "300px" },
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <SelectInput
                label={t("auth:form.category")}
                placeholder={t("auth:form.category")}
                name="categoryId"
                formik={formik}
                options={categories}
                renderValue={(option) => option?.id}
                renderOptionLabel={(option) =>
                  lang === "en" ? option?.name : option?.nameAr
                }
                loading={loadingCategories}
                MenuProps={{
                  PaperProps: {
                    onScroll: onScrolled,
                    sx: { maxHeight: "300px" },
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextInput
                label={t("requests.comment")}
                placeholder={t("requests.comment_placeholder")}
                name="comment"
                formik={formik}
                multiline
                rows={4}
                onKeyDown={handleKeyDown}
              />
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: 1,
                }}
              >
                <label
                  htmlFor="contained-button-file"
                  style={{ width: "100%" }}
                >
                  <Input
                    id="contained-button-file"
                    inputProps={{
                      accept: "image/jpeg, image/png, image/gif",
                    }}
                    type="file"
                    sx={{ display: "none" }}
                    onChange={uploadImage}
                    error={formik.touched.image && Boolean(formik.errors.image)}
                  />
                  <Box
                    sx={{
                      borderRadius: "6px",
                      backgroundColor: "background.secondary",
                      overflow: "hidden",
                      width: 1,
                      height: "163px",
                      position: "relative",
                    }}
                  >
                    {image?.media ? (
                      <>
                        <IconButton
                          sx={{
                            position: "absolute",
                            zIndex: "2",
                            right: 5,
                            color: "white",
                          }}
                          onClick={() => setImage()}
                        >
                          <CancelOutlinedIcon />
                        </IconButton>
                        <img
                          src={image?.media}
                          style={{
                            width: "100%",
                          }}
                          alt=""
                        />
                      </>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          height: 1,
                        }}
                      >
                        <CloudUploadOutlinedIcon
                          sx={{ color: "text.secondary" }}
                        />
                        <Typography color="text.secondary">
                          {t("requests.image")}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </label>
              </Box>
              {formik.errors.image && (
                <Box width={1} textAlign="center">
                  <Typography
                    variant="caption"
                    textALign="center"
                    color="secondary"
                    width={1}
                  >
                    {formik.errors.image}
                  </Typography>
                </Box>
              )}
            </Grid>

            <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
              <Button variant="contained" type="submit">
                {t("auth:form.submit")}
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddRequestFormDialog;
