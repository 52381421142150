// FileRenderer.js

import React, { useState } from "react";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DescriptionIcon from "@mui/icons-material/Description";
import { Box, Button, Dialog, DialogContent } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";

const FileRenderer = ({ fileUrl }) => {
  const [openDialog, setOpenDialog] = useState(false);

  const getFileType = (url) => {
    if (!url) return null;

    if (url.endsWith(".pdf")) return "pdf";
    if (url.endsWith(".xlsx") || url.endsWith(".xls")) return "excel";
    if (url.endsWith(".docx") || url.endsWith(".doc")) return "word";
    if (url.startsWith("http") && url.match(/\.(jpeg|jpg|gif|png)$/))
      return "image";

    return "unknown";
  };

  const fileType = getFileType(fileUrl);

  const handleImageClick = () => {
    if (fileType === "image") {
      setOpenDialog(true);
    } else {
      window.open(fileUrl, "_blank");
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Box>
      <Button
        variant="outlined"
        onClick={() =>
          fileType === "image"
            ? handleImageClick()
            : window.open(fileUrl, "_blank")
        }
      >
        {fileType === "pdf" && (
          <PictureAsPdfIcon
            color="error"
            fontSize="large"
            sx={{ cursor: "pointer", width: "60px", height: "60px" }}
          />
        )}
        {fileType === "excel" && (
          <InsertDriveFileIcon
            color="success"
            fontSize="large"
            onClick={() => window.open(fileUrl, "_blank")}
            sx={{ cursor: "pointer", width: "60px", height: "60px" }}
          />
        )}
        {fileType === "word" && (
          <DescriptionIcon
            color="primary"
            fontSize="large"
            onClick={() => window.open(fileUrl, "_blank")}
            sx={{ cursor: "pointer", width: "60px", height: "60px" }}
          />
        )}
        {fileType === "image" && (
          <ImageIcon
            color="primary"
            fontSize="large"
            sx={{ cursor: "pointer", width: "60px", height: "60px" }}
          />
        )}
        {fileType === "unknown" && (
          <InsertDriveFileIcon
            color="text.secondary"
            fontSize="large"
            sx={{ cursor: "pointer", width: "60px", height: "60px" }}
          />
        )}
      </Button>

      {/* Modal for displaying the image */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="md"
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            outline: "none",
            border: "none",
          },
        }}
      >
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={fileUrl}
            alt="Enlarged view"
            style={{
              maxWidth: "100%",
              maxHeight: "80vh",
              objectFit: "contain",
            }}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default FileRenderer;
