import { lazy, Suspense, useMemo } from "react";
import Navbar from "../Layout/Navbar";
import Footer from "../Layout/Footer";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import PageLoader from "../Components/PageLoader";
import { Box, Typography } from "@mui/material";
import DataBanner from "./../Layout/DataBanner";
import MinAppBar from "../Layout/MinAppBar";
import InProgress from "../Pages/Home/Supplier/InProgress";
import UnderReview from "../Pages/Home/Supplier/UnderReview";
import NewRequests from "../Pages/Home/Supplier/NewRequests";

const Home = lazy(() => import("../Pages/Home"));
const Settings = lazy(() => import("../Pages/Settings"));
const OffersPage = lazy(() => import("../Pages/Offers"));
const ProjectsPage = lazy(() => import("../Pages/Projects"));
const BillsPage = lazy(() => import("../Pages/Bills"));
const AddRequest = lazy(() => import("../Pages/AddRequest"));
const ProjectDetails = lazy(() => import("../Pages/Projects/ProjectDetails"));
const SingleRequest = lazy(() => import("../Pages/SingleRequest"));
const Chat = lazy(() => import("../Pages/Settings/Chats/index"));

const Main = () => {
  const isAuthenticated = useSelector((s) => s?.auth?.isAuthenticated);

  let currentLocationForRedirect = useMemo(() => {
    return window.location.href?.split(window?.location?.origin);
  }, []);
  if (!isAuthenticated)
    return (
      <Navigate
        to="/login"
        state={{
          currentLocationForRedirect: currentLocationForRedirect
            ? currentLocationForRedirect[1]
            : null,
        }}
        replace
      />
    );
  return (
    <>
      <DataBanner />
      <Navbar />
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<PageLoader />}>
              <Home />
            </Suspense>
          }
        />

        <Route
          path="projects"
          element={
            <Suspense fallback={<PageLoader />}>
              <ProjectsPage />
            </Suspense>
          }
        />

        <Route
          path="offers"
          element={
            <Suspense fallback={<PageLoader />}>
              <OffersPage />
            </Suspense>
          }
        />

        <Route
          path="chat"
          element={
            <Suspense fallback={<PageLoader />}>
              <Chat />
            </Suspense>
          }
        />

        <Route
          path="bills"
          element={
            <Suspense fallback={<PageLoader />}>
              <BillsPage />
            </Suspense>
          }
        />

        <Route
          path="add-request"
          element={
            <Suspense fallback={<PageLoader />}>
              <AddRequest />
            </Suspense>
          }
        />

        <Route
          path="project/:projectId"
          element={
            <Suspense fallback={<PageLoader />}>
              <ProjectDetails />
            </Suspense>
          }
        />

        <Route
          path="request/:requestId"
          element={
            <Suspense fallback={<PageLoader />}>
              <SingleRequest />
            </Suspense>
          }
        />

        <Route
          path="settings"
          element={
            <Suspense fallback={<PageLoader />}>
              <Settings />
            </Suspense>
          }
        />
        <Route
          path="inProgress"
          element={
            <Suspense fallback={<PageLoader />}>
              <InProgress />
            </Suspense>
          }
        />
        <Route
          path="underReview"
          element={
            <Suspense fallback={<PageLoader />}>
              <UnderReview />
            </Suspense>
          }
        />
        <Route
          path="newRequests"
          element={
            <Suspense fallback={<PageLoader />}>
              <NewRequests />
            </Suspense>
          }
        />

        <Route
          path="404"
          element={
            <Typography variant="h4" sx={{ p: "2em", textAlign: "center" }}>
              Not Found
            </Typography>
          }
        />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>

      <MinAppBar />
      <Footer />
      <Box
        sx={{
          bgcolor: "transparent",
          width: 1,
          height: "170px",
          display: { xs: "block", sm: "none" },
        }}
      ></Box>
    </>
  );
};

export default Main;
