import { Box, Tabs, Typography, Tab, IconButton, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { footerBg } from "../assets/index";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function Footer() {
  const { t } = useTranslation(["global"]);

  const navigate = useNavigate();
  const location = useLocation();
  const userData = useSelector((state) => state?.auth?.user);

  const path = location.pathname;

  const supplierTabs = [
    { name: "home", value: "/" },
    {
      name: "new_requests",
      value: "/newRequests",
    },
    {
      name: "requests_review",
      value: "/underReview",
    },
    {
      name: "in_progress_requests",
      value: "/inProgress",
    },
    { name: "settings", value: "/settings" },
  ];
  const tabs = [
    { name: "home", value: "/" },
    {
      name: "projects",
      value: "/projects",
    },
    {
      name: "bills",
      value: "/bills",
    },
    {
      name: "settings",
      value: "/settings",
    },
  ];

  return (
    <Box
      sx={{
        display: { xs: "none", sm: "block" },
      }}
    >
      <Box
        sx={{
          backgroundImage: `url(${footerBg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          p: "2em",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center", mt: "1em" }}>
          <Tabs
            value={path}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            sx={{
              margin: "0 auto",
              "& .MuiTabs-indicator": {
                display: "none",
              },
              "& .MuiTab-root.Mui-selected": {
                color: "primary.main",
              },
              "& .MuiTab-root": {
                color: "background.paper",
                transition: "color 0.3s ease-in-out",
                "&:hover": {
                  color: "primary.main",
                },
              },
              "& .MuiTabs-scrollButtons": {
                color: "white",
              },
            }}
          >
            {userData.accountType === "user" ? (
              <>
                {tabs?.map((item, index) => (
                  <Tab
                    key={index}
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() => navigate(`${item?.value}`)}
                      >
                        <Typography variant="h6" sx={{ fontWeight: "600" }}>
                          {t(item?.name)}
                        </Typography>
                      </Box>
                    }
                    value={item?.value}
                  />
                ))}
              </>
            ) : (
              <>
                {supplierTabs?.map((item, index) => (
                  <Tab
                    key={index}
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() => navigate(`${item?.value}`)}
                      >
                        <Typography variant="h6" sx={{ fontWeight: "600" }}>
                          {t(item?.name)}
                        </Typography>
                      </Box>
                    }
                    value={item?.value}
                  />
                ))}
              </>
            )}
          </Tabs>
        </Box>
      </Box>
      <Box
        sx={{
          bgcolor: "#030C16",
          p: "0.5em",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "500", color: "background.paper" }}
        >
          {t("footer_copyright")}
        </Typography>
      </Box>
    </Box>
  );
}
