import store from "../..";
import {
  createRequestService,
  deleteRequestService,
  listRequestsService,
  listSingleRequestService,
  updateRequestService,
} from "../../../services/requests";

import {
  CREATE_REQUEST,
  GET_REQUESTS,
  REQUESTS_ERROR,
  REQUESTS_LOADING,
  DELETE_REQUEST,
  REQUEST_ERROR,
  REQUEST_LOADING,
  GET_REQUEST,
  UPDATE_REQUEST,
} from "../types";

export const getRequestsAction =
  ({ page, limit, next, projectId, hasOffers, isNew }) =>
  (dispatch) => {
    if (next) {
      let { nextPage, loading, hasMore } = store?.getState()?.requests;

      if (loading) return;
      if (!hasMore) return;
      page = nextPage;
    }
    dispatch({ type: REQUESTS_LOADING });

    return listRequestsService({
      page,
      limit,
      projectId,
      hasOffers,
      isNew,
    })
      .catch((e) =>
        dispatch({
          type: REQUESTS_ERROR,
          payload: e,
        })
      )
      .then((d) => {
        if (d.error) {
          return dispatch({
            type: REQUESTS_ERROR,
            payload: d.error,
          });
        }

        dispatch({
          type: GET_REQUESTS,
          payload: d,
        });
      });
  };

export const createRequestAction =
  ({ sendData, cb = () => {} }) =>
  (dispatch) => {
    return createRequestService({ sendData }).then((res) => {
      const { error, data } = res;
      if (error) return cb(error);
      dispatch({ type: CREATE_REQUEST, payload: data });
      cb && cb(null);
    });
  };

export const deleteRequestAction =
  ({ id, cb = () => {} }) =>
  (dispatch) => {
    return deleteRequestService({ id }).then((res) => {
      const { error } = res;
      if (error) {
        cb(error);
        return dispatch({
          type: REQUESTS_ERROR,
          payload: error,
        });
      }

      dispatch({ type: DELETE_REQUEST, payload: { id } });
      cb && cb(null);
    });
  };

export const getSingleRequestAction =
  ({ requestId, isRead }) =>
  (dispatch) => {
    dispatch({ type: REQUEST_LOADING });

    return listSingleRequestService({
      requestId,
      isRead,
    })
      .catch((e) =>
        dispatch({
          type: REQUEST_ERROR,
          payload: e,
        })
      )
      .then((d) => {
        if (d.error) {
          return dispatch({
            type: REQUEST_ERROR,
            payload: d.error,
          });
        }

        dispatch({
          type: GET_REQUEST,
          payload: d,
        });
      });
  };

export const updateRequestAction =
  ({ updatedData, cb = () => {} }) =>
  (dispatch) => {
    return updateRequestService({ updatedData }).then((res) => {
      const { error, data } = res;
      if (error) return cb(error);
      dispatch({ type: UPDATE_REQUEST, payload: data });
      cb && cb(null);
    });
  };
