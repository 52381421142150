import { getRequest } from "../connection/network";
const userApis = {
  single: "user",
  list: "user/list",
  calendar: "user/list/dataType",
};

export const getSingleUserProfile = async ({ userId }) => {
  try {
    const token = localStorage.getItem("token");
    let {
      data: { data, message },
    } = await getRequest({
      endPoint: userApis.single,
      token,
      query: { id: userId },
    });
    return {
      data,
      message,
    };
  } catch (error) {
    const sError = error?.response?.data?.error?.message;
    return { error: sError };
  }
};
export const fetchUsersListService = async ({ temporaryToken, ...query }) => {
  try {
    const token = localStorage.getItem("token");
    let {
      data: { data, message },
    } = await getRequest({
      endPoint: userApis.list,
      token: token || temporaryToken,
      query: { ...query },
    });
    return {
      data,
      message,
    };
  } catch (error) {
    const sError = error?.response?.data?.error?.message;
    return { error: sError };
  }
};

export const fetchCalendarService = async ({
  page,
  limit,
  calendar,
  startDate,
  endDate,
}) => {
  try {
    const token = localStorage.getItem("token");
    let {
      data: { data, message },
    } = await getRequest({
      endPoint: userApis.calendar,
      token: token,
      query: { page, limit, calendar, startDate, endDate },
    });
    return {
      data,
      message,
    };
  } catch (error) {
    const sError = error?.response?.data?.error?.message;
    return { error: sError };
  }
};
