import { CircularProgress, Tooltip } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { useSelector } from "react-redux";
import { getSingleUserProfile } from "../../services/user";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";

export default function UserAvatar({
  src,
  alt,
  type = "user",
  color,
  sx = {},
  userId,
  onClick,
}) {
  const [loadingChat, setLoadingChat] = useState(false);
  const [_, setSearchParams] = useSearchParams();
  const chatNow = (chatId) => setSearchParams({ chatId });

  const getUserProfile = async () => {
    const { error, data } = await getSingleUserProfile({ userId });
    if (error) {
      setLoadingChat(false);
      return;
    }

    return data;
  };

  const authUserId = useSelector((s) => s?.auth?.user?.id);
  const clicked = async () => {
    if (loadingChat) return;
    if (userId && userId !== authUserId) {
      setLoadingChat(true);
      const { chat } = await getUserProfile();
      chatNow(chat?.id);
      setLoadingChat(false);
    }
  };

  return (
    <Tooltip title={alt || ""} placement="top">
      <Avatar
        src={src}
        alt={alt || ""}
        sx={{
          backgroundColor: color,
          alignContent: "center",
          alignItems: "center",
          textAlign: "center",
          cursor: "pointer",
          ...sx,
        }}
        onClick={onClick ? onClick : clicked}
        imgProps={{ loading: "lazy" }}
      >
        {loadingChat && <CircularProgress />}
        {String(alt)[0]}
      </Avatar>
    </Tooltip>
  );
}
