import { Box, Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect } from "react";
import { KeyboardDoubleArrowDownOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import ImageWithOverlappedCard from "../../../../Components/ImageWithOverlappedCard";
import { getReviewOffersAction } from "../../../../store/actions/offers";
import OfferItem from "../../../Settings/OffersList/OfferItem";
import { requestsEmpty } from "../../../../assets";
import Loader from "../../../../Components/Loader";

export default function UnderReview() {
  const { t } = useTranslation(["global"]);

  const dispatch = useDispatch();

  const offersList = useSelector((s) => s?.offers.reviewOffers?.data);
  const loading = useSelector((s) => s?.offers.reviewOffers?.loading);
  const hasMore = useSelector((s) => s?.offers.reviewOffers?.hasMore);
  const count = useSelector((s) => s?.reviewOffers?.count);

  const getOffers = useCallback(
    ({ next = false }) => {
      getReviewOffersAction({ limit: 6, next, status: "pending" })(dispatch);
    },
    [dispatch]
  );

  useEffect(() => {
    getOffers && getOffers({ page: 1 });
  }, [getOffers]);

  const handleSeeMore = () => {
    getOffers({ next: true });
  };

  return (
    <Box id="UnderReview">
      {/* <ImageWithOverlappedCard
        data={offersList}
        loading={loading}
        hasTitle={false}
        hasButton={false}
        emptyTitle={t("no_requests_title")}
        emptySubtitle={t("no_requests_subtitle")}
        linkUrl={"request"}
        renderId={(item) => item?.request?.id}
        renderName={(item) => item?.request?.project?.name}
        hasPrice
      /> */}

      {/* {hasMore && offersList.length > 0 && (
        <Box sx={{ display: "flex", justifyContent: "center", margin: "2em" }}>
          <Button
            variant="text"
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              gap: "1em",
            }}
            onClick={handleSeeMore}
          >
            <Typography variant="body1" sx={{ color: "#615F5F" }}>
              {t("see_more")}
            </Typography>
            <KeyboardArrowDown sx={{ color: "#615F5F" }} />
          </Button>
        </Box>
      )} */}

      <Box sx={{ mt: "2em" }}>
        {offersList?.length > 0 ? (
          <>
            {offersList?.map((request) => (
              <OfferItem key={request?.id} offer={request} isProject isReview/>
            ))}
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: 1,
              py: 8,
            }}
          >
            <img src={requestsEmpty} alt="" />
            <Typography color="text.secondary" fontWeight="bold" sx={{ mt: 2 }}>
              {t("requests.empty")}
            </Typography>
            <Typography variant="subtitle2" color="text.secondary">
              {t("requests.empty_sub")}
            </Typography>
          </Box>
        )}
        {loading && (
          <Box
            sx={{ width: 1, display: "flex", justifyContent: "center", py: 5 }}
          >
            <Loader />
          </Box>
        )}
        {hasMore && !loading && offersList?.length > 0 && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button onClick={() => getOffers({ next: true })}>
              <KeyboardDoubleArrowDownOutlined />
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
}
