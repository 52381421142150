import {
  delRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../connection/network";

const requestApi = "request";

export const listRequestsService = async ({
  page,
  limit,
  projectId,
  hasOffers,
  isNew,
}) => {
  try {
    const token = localStorage.getItem("token");
    const {
      data: {
        data,
        meta: { thisPage, nextPage },
      },
    } = await getRequest({
      token,
      query: { page, limit, projectId, hasOffers, isNew },
      endPoint: requestApi,
    });

    return {
      data,
      thisPage: parseInt(thisPage),
      nextPage: parseInt(nextPage),
      hasMore: data?.resData?.length === limit,
    };
  } catch (error) {
    const sError =
      error?.response?.data?.error?.message || "Unknown error occurred";
    return { error: sError };
  }
};

export const createRequestService = async ({ sendData }) => {
  try {
    const token = localStorage.getItem("token");
    let {
      data: { data, message },
    } = await postRequest({
      body: sendData,
      endPoint: requestApi,
      token,
      isFormData: true,
    });

    return {
      data,
      message,
    };
  } catch (error) {
    const sError =
      error?.response?.data?.error?.message || "Unknown error occurred";
    return { error: sError };
  }
};

export const deleteRequestService = async ({ id }) => {
  try {
    const token = localStorage.getItem("token");
    const {
      data: { data },
    } = await delRequest({
      token,
      query: { id },
      endPoint: requestApi,
    });

    return {
      data,
    };
  } catch (error) {
    const sError =
      error?.response?.data?.error?.message || "Unknown error occurred";
    return { error: sError };
  }
};

export const listSingleRequestService = async ({ requestId, isRead }) => {
  try {
    const token = localStorage.getItem("token");
    const {
      data: { data },
    } = await getRequest({
      token,
      endPoint: requestApi,
      params: requestId,
      query: { isRead },
    });

    return {
      data,
    };
  } catch (error) {
    const sError =
      error?.response?.data?.error?.message || "Unknown error occurred";
    return { error: sError };
  }
};

export const updateRequestService = async ({ updatedData }) => {
  try {
    const token = localStorage.getItem("token");
    const {
      data: { data },
    } = await putRequest({
      token,
      body: updatedData,
      endPoint: `${requestApi}`,
      isFormData: true,
    });

    return {
      data,
    };
  } catch (error) {
    const sError =
      error?.response?.data?.error?.message || "Unknown error occurred";
    return { error: sError };
  }
};
