import { SET_CURRENT_USERS } from "../actions/types";
import { createReducer } from "@reduxjs/toolkit";
const initials = {
  users: {},
};

const usersReducer = createReducer(initials, (builder) => {
  builder.addCase(SET_CURRENT_USERS, (s, { payload }) => {
    s.users = payload;
  });
});

export default usersReducer;
