import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ImageIcon from "@mui/icons-material/Image";
import DescriptionIcon from "@mui/icons-material/Description";

export default function GenerateFileIcon(file) {
  if (!file) {
    return <CloudUploadOutlinedIcon sx={{ color: "text.secondary" }} />;
  }

  const fileType = file.type;

  if (fileType === "application/pdf") {
    return <PictureAsPdfIcon color="error" fontSize="large" />;
  } else if (
    fileType === "application/msword" ||
    fileType ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  ) {
    return <DescriptionIcon color="primary" fontSize="large" />;
  } else if (
    fileType === "application/vnd.ms-excel" ||
    fileType ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  ) {
    return <InsertDriveFileIcon color="success" fontSize="large" />;
  } else if (fileType.startsWith("image/")) {
    return <ImageIcon color="primary" fontSize="large" />;
  } else {
    return <InsertDriveFileIcon color="text.secondary" fontSize="large" />;
  }
}
